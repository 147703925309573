<template>
  <div class="app flex-row align-items-center"></div>
</template>
<script>
import api from "../../api/api";

export default {
  data() {
    return {};
  },
  created: async function() {
    await api.logoutUser().then(() => {
      this.$store.dispatch("auth/clearToken");
      this.$router.push({ name: "Login" });
    });
  }
};
</script>
